import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Section,
  SectionHeading
} from "../styledComponents/section"
import ProductBlackFridayOfferList from "../components/ProductBlackFridayOfferList";

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align="center">
    {children}
  </SectionHeading>
)

const BlackFridayOffer = ({ data, location }) => {
  let products = data.allWpProduct.edges

    return (
      <Layout location={location} isHomePage>
        <SEO title="Karina Grant - Energy healing" Description="Karina Grant is a energy and chakra healer working from the world renowned Hale Clinic in London." />
        <Section>
          <Heading level="2">Gift yourself ANY workshop and I will FREELY gift YOU all this <strong>worth a massive £156.96</strong> <span> <em>or</em> </span>
          Gift a loved one ANY workshop and you will BOTH receive all these bonus gifts <strong>worth a massive £313.92</strong></Heading>
          <ProductBlackFridayOfferList posts={products} />
          
        </Section>

        {/* <BackgroundColourBlock number='2'>
          <Section>
            <SectionHeading>
              <Heading level="2">And gift a loved one any workshop &amp; I will FREELY gift you BOTH </Heading>
            </SectionHeading>
            <ProductBlackFridayOfferList posts={products} />
            <Heading level="2"><span>Worth a massive </span></Heading>
          </Section>
        </BackgroundColourBlock> */}

      </Layout>
    )
}

export default BlackFridayOffer


export const pageQuery = graphql`
  query BlackFridayOffer {
    allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {productsTag: {nodes: {elemMatch: {slug: {eq: "black-friday-offer"}, contentNodes: {nodes: {elemMatch: {}}}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
