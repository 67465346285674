import React, { useState } from "react"
import { useShoppingCart } from 'use-shopping-cart'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import ProductCardPromo from "./ProductCardPromo"

const BlackFridayPromoProductCard = props => {

  const identity = useIdentityContext()
  const [processing, setProcessing] = useState(false);
  const [user, setUser] = useState(false);
  const { addItem } = useShoppingCart()
  const { 
    id,
    featuredImage, 
  } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  return (
    <ProductCardPromo data={props} />
  );
}

export default BlackFridayPromoProductCard
