import React from "react"


import {
  StyledProductListPromo,
  StyledProductListPromoItem,
} from "./Products/productArchivePromo"

import BlackFridayPromoProductCard from "./Products/BlackFridayPromo"

const ProductBlackFridayOfferList = ({ posts, pageType, padding }) => {
  return (
    <StyledProductListPromo pageType={pageType} padding={padding}>
      {posts.map((post, index) => {
          return(
            
            <StyledProductListPromoItem key={post.node.id}>
              <BlackFridayPromoProductCard
                  title={post.node.title}
                  id={post.node.id}
                  uri={post.node.uri}
                  excerpt={post.node.excerpt}
                  featuredImage={post.node.featuredImage}
                  pricefull={post.node.productData.pricefull}
                  pricesale={post.node.productData.pricesale}
                  amazonLink={post.node.productData.linkToAmazon}
                  stripefullprice={post.node.productData.stripefullprice}
                  stripesaleprice={post.node.productData.stripesaleprice}
                  productTag={post.node.productsTag.nodes[0]?.slug}
                />  
                
            </StyledProductListPromoItem>
            
          )
      })}
    </StyledProductListPromo>
  )
}

export default ProductBlackFridayOfferList
