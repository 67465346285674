import React, { useState } from "react"
import styled from "styled-components"
import { useShoppingCart } from 'use-shopping-cart'
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { StyledModal,StyledModalHeader } from "../../styledComponents/modal"
import Icon from "../icon"
import KarinaGrantLogo from "../logo"
import lunchtimeReset from "../../fixtures/mindfulness-collection-lunchtime-reset"
import { StyledAudio } from "../../styledComponents/app/audioCard"
import AudioModal from "../App/AudioModal"

import {
  StyledProductPrimaryCardPromo,
  ProductHeaderPromo,
  ProductExcerptPromo,
  ProductFigurePromo,
  ProductFigureCaptionPromo
} from "./StyledPrimaryProductCardPromo"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import { StyledButton, StyledAddToCartButton, StyledRegisterNowButton, StyledCloseModalButton, StyledLinkButton } from "../../styledComponents/button"

import { navigate } from "@reach/router";
function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    
    setOpacity(0);
    setIsOpen(!isOpen);
  }
  
  function closeModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }



  const collection = lunchtimeReset 


  return (
      <>
      <StyledButton className="listen-now" onClick={toggleModal}>Listen</StyledButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="audio-list-modal"
      >
        <StyledModalHeader>
          <div>
            <KarinaGrantLogo />
            <StyledCloseModalButton className="close-audio" onClick={closeModal}>
              <Icon name="cross" />
            </StyledCloseModalButton>
          </div>
        </StyledModalHeader>
        <StyledAudio className="single-audio">
          <AudioModal audio={collection[0]}/>     
        </StyledAudio>
        </StyledModal>
      </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const ProductCardPromo = props => {
    const { 
        title
      } = props
      const { addItem, checkoutSingleItem } = useShoppingCart()
      const image = {
        imageData: props.data.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
        alt: props.data.featuredImage?.node?.alt || ``,
        description: props.data.featuredImage?.node?.description || "",
      }

      const goToProduct = (url) => {
        if(url === '/product/self-healing-mastery') {
          navigate(url)
        } else {
          navigate('/self-healing-mastery')
        }
      }
    return (
        <StyledProductPrimaryCardPromo
      itemScope
      itemType="http://schema.org/Product"
      onClick={() => {goToProduct(props.data.uri)}}
    >
      {image && image.imageData && (
          <ProductFigurePromo>
            <GatsbyImage
              image={image.imageData}
              alt={image.alt}
              width="100%"
              padding-bottom="0" />
            <ProductFigureCaptionPromo>{image.description}</ProductFigureCaptionPromo>
          </ProductFigurePromo>
        )}
        <ProductHeaderPromo onClick={() => {goToProduct(props.data.uri)}} itemProp="name">{props.data.title}</ProductHeaderPromo>
        <ProductExcerptPromo>{parse(props.data.excerpt)}</ProductExcerptPromo>
        <div className="product-price promo">
            <span itemProp="lowPrice">{props.data.pricesale}</span>  &pound;{props.data.pricefull}
        </div>
    </StyledProductPrimaryCardPromo>
    )
}
export default ProductCardPromo