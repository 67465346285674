import styled from "styled-components"
import { regular12, regular16, bold18, bold24 } from "../../styledComponents/typography"
import breakpoints from "../../styledComponents/breakpoints"
import { baseGridStyles, strikethrough } from "../../styledComponents/base";

const StyledProductPrimaryCardPromo = styled.article`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  box-shadow: 0 4px 15px 0 rgb(40 44 53 / 6%), 0 2px 2px 0 rgb(40 44 53 / 8%);
  background-color: #fff;
  border-radius: 4px;
  padding-bottom: 1.6rem;
  ${baseGridStyles}
  padding: 0;
  grid-row-gap: 0;
  padding-bottom: 1.6rem;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0 1.6rem 1.6rem;
    grid-row-gap: 0;
    min-height:39rem;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    min-height: 42rem;
    display: block;
  }
  
  &:hover {
    cursor: pointer;
  }
  
  p {
    margin: 0;
    padding-bottom: 1.6rem;
  }

  a {
    
    &.more-info{
      grid-column: 1 / 5;
      text-align: center;
      margin-bottom: 1.6rem;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 1 / 7;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 1 / 6;
        text-align: left;
        margin-top: 0;
        display: inline;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: 1 / 6;
        text-align: left;
        ${regular16}
        
      }
    }


    &:hover {
      button {
        background: var(--light-purple-darkest);
        cursor: pointer;
      }
    }
  }
  // resets button in audio player
  .rhap_container button {
      margin: 0; 
    }

  a.amazon {
    grid-column: span 4;
    background-color: var(--green-strong);
    margin-top: 1.6rem;
    background: #FFA41C;
    border-color: #FF8F00;
    width: 100%;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: span 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 3 / 10;
      height: fit-content;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column: 1 / 13;
      margin-top: 0;
    }
  }
  button {
    margin: 1.6rem 1.6rem 0; 

    @media screen and (min-width: ${breakpoints.sm}px) {
      margin: 1.6rem 0 0; 
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      width: 100%;
    }

    &.listen-now {
      grid-column: span 4;
      background-color: var(--green-strong);

      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 10;
        margin-top: 1.6rem;
        height: fit-content;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: 1 / 13;
        margin-top: 0;
      }
    }
  }
  .sales-buttons {
    ${baseGridStyles}
    grid-gap: 0.8rem;
    padding: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      margin: 0;  
    }
    button {
      grid-column: span 4;
      width: auto;
      font-weight: 700;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 6;
        margin-top: 0;
        min-width: 13rem;
      }
      &:nth-child(2) {
        background-color: var(--light-purple-darkest);
        margin-top: 0;
      }
    }
  }

  
  .product-price {
      position: absolute;
      top: 0.8rem;
      right: 0;
      background: var(--light-purple-darkest);
      color: var(--white);
      ${regular12}
      border: 1px solid var(--white);
      border-right:0;
      padding: 0.2rem 0.6rem;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: 700;
      &.sale {
        background-color: var(--red-mid);
      }

      .strikethrough {
        ${strikethrough}
        color: var(--white);
      }
    }  
  img {
    cursor: pointer;
  }
  span.hidden {
    display: none;
  }
`

const ProductHeaderPromo = styled.h2`
  margin: 0.8rem 0 0;
  ${bold18}
  color: var(--light-purple-darker);
  grid-column: 1 / 5;
  padding-left: 1.6rem;
  text-align: left;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
    grid-column: 1 / 7;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 13;
  }

  a {
    text-decoration: none;
  }
`

const ProductExcerptPromo = styled.div`
  grid-column: 1 / 5;
  padding-left: 1.6rem;
  padding-right:1.6rem;
  margin: 0.8rem 0 0;
  text-align: left;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 7;
    padding-left: 0;
    margin: 0.8rem 0 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 12;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
  
  p{
    display: inline;
    
    @media screen and (min-width: ${breakpoints.md}px) {
      ${regular16}
      padding-bottom: 0;
    }
  }

  .gift-item & {
    p {
      display: inline-block;
      padding-bottom: 1.6rem;

      &:last-child{ 
      padding-bottom: 0;
      }
    }
  }

  a {
    display: inline;
  }
`

const ProductFigurePromo = styled.figure`
  grid-column: span 4;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 12;
  }

  &:first-child {
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: -1.6rem;
      margin-right: -1.6rem;
    }  
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const ProductFigureCaptionPromo = styled.figcaption`
  margin: 0;
  padding: 0;
`



const StyledProductSingleCardPromo = styled.article`
  grid-column: span 4;
  position: relative;
  /* padding: 0 1.6rem 1.6rem; */

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 12;
    padding: 0;
  }


  & > span {
    ${baseGridStyles}
    padding: 0;
    grid-row-gap: 0;
   
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0 1.6rem;
      grid-row-gap: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 0;
    }

    &.url {
      display: none;
    }
  }
 
  .product-price.sale {
    position: absolute;
    top: 0rem;
    display: inline-block;
    left: 3.8rem;
    width: max-content;
    background: var(--white);
    color: var(--green-strong);
    border: 0.1rem solid rgba(255,255,255,.5);
    padding: 0.4rem 0.8rem 0.4rem 1.6rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
      top: 0rem;

    }
    @media screen and (min-width: ${breakpoints.md}px) {
      position: absolute;
      top: 0;
      left: 3rem;
      border: 0.1rem solid rgba(255,255,255,.8);
    }

    .strikethrough {
      ${strikethrough}
      color: var(--green-strong);
    }
  }
  
  .product-price,
  .product-description {
    ${regular12}
    margin: 0.8rem 0 0;
    grid-column: 1 / 5;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 8 / 12; 
    }
  }

  .product-description {
    ${baseGridStyles}
    padding: 0;
    & * {
      grid-column: 1 / 5;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 1 / 8;
      }
      
    }

    p {
      margin-top: 1.6rem;;
    }
    h2, h3, ul, ol {
      @media screen and (min-width: ${breakpoints.sm}px) {
        margin-left: 0;
      }
    }
  }
`

const StyledProductSingleFigurePromo = styled.figure`
  grid-column: span 4;
  padding: 0;

  &:first-child {
    margin: 0 -1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: span 6;
      margin: 0 -2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 7;
      grid-row: span 7;
      margin: 0;
    }
  }
`

const StyledProductSingleCalendarCardPromo = styled.div`
  width: 50px;
  background: #fff;
  border: 1px solid #ddd;
  padding-top: 1rem;
  text-align: center;
  padding: 1rem 0 0;
  margin-bottom: 2rem;
  margin-top: 0;
  margin-right: 1.5rem;
  height: 58px;
  position: absolute;
  top: 0.8rem;
  z-index: 2;
  left: 0.8rem;
  margin-top: 0.2rem;
  grid-row: 2 / span 3;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  span {
    display: block;
  }
`

const StyledProductSingleHeaderPromo = styled.h2`
  color: var(--grey-darker);
  margin: 1.6rem 0 0.8rem;
  ${bold18}
  color: var(--light-purple-darker);
  grid-column: 1 / 5;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 2.8rem 0 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 12; 
    ${bold24}
  }

  a {
    text-decoration: none;
  }
`
const StyledProductBookingButtonsPromo = styled.div`
  grid-column: 1 / 5; 
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 12; 
  }

  a.amazon {
    grid-column: span 4;
    background-color: var(--green-strong);
    margin-top: 1.6rem;
    background: #FFA41C;
    border-color: #FF8F00;
    width: 100%;
    @media screen and (min-width: ${breakpoints.sm}px) {
      width: 100%;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 3 / 10;
      height: fit-content;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      width: 60%;
      margin-left: 0;
    }
  }
`


const StyledProductExcerptSinglePromo = styled.div`
  grid-column: 1 / 5;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
    grid-column: 1 / 7;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 8 / 12;
    padding-left: 0;
    padding-right: 0;
  }
`

export {
  StyledProductPrimaryCardPromo,
  ProductHeaderPromo,
  ProductExcerptPromo,
  ProductFigurePromo,
  ProductFigureCaptionPromo,
  StyledProductSingleCardPromo,
  StyledProductSingleFigurePromo,
  StyledProductSingleCalendarCardPromo,
  StyledProductSingleHeaderPromo,
  StyledProductBookingButtonsPromo,
  StyledProductExcerptSinglePromo
}
